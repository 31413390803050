import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "text-secondary" }
const _hoisted_2 = { class: "border-b p-2" }
const _hoisted_3 = { class: "text-sm" }
const _hoisted_4 = { class: "text-sm" }
const _hoisted_5 = { class: "lowercase" }
const _hoisted_6 = { class: "text-sm capitalize" }
const _hoisted_7 = { class: "text-sm" }
const _hoisted_8 = { class: "text-sm capitalize" }

import { TransactionReceipt } from '@ethersproject/abstract-provider';
import { getAddress } from 'ethers/lib/utils';
import { computed, onBeforeMount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQueryClient } from 'vue-query';

import AnimatePresence from '@/components/animate/AnimatePresence.vue';
import ConfirmationIndicator from '@/components/web3/ConfirmationIndicator.vue';
import useStaking from '@/composables/staking/useStaking';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import useTokenApprovalActions from '@/composables/useTokenApprovalActions';
import useTokens from '@/composables/useTokens';
import { bnum } from '@/lib/utils';
import { getGaugeAddress } from '@/providers/local/staking/staking.provider';
import { PoolWithShares } from '@/services/pool/types';
import useWeb3 from '@/services/web3/useWeb3';
import { TransactionActionInfo } from '@/types/transactions';

export type StakeAction = 'stake' | 'unstake';
type Props = {
  pool: PoolWithShares;
  action: StakeAction;
};


export default _defineComponent({
  props: {
    pool: { type: null, required: true },
    action: { type: String, required: true }
  } as unknown as undefined,
  emits: ['close', 'success'],
  setup(__props: {
  pool: PoolWithShares;
  action: StakeAction;
}, { emit }) {

const props = __props



/**
 * COMPOSABLES
 */
const { balanceFor, getToken } = useTokens();
const { fNum2 } = useNumbers();
const { t } = useI18n();
const queryClient = useQueryClient();
const { getProvider } = useWeb3();

const {
  userData: {
    stakedSharesForProvidedPool,
    refetchStakedShares,
    refetchUserStakingData
  },
  stakeBPT,
  unstakeBPT
} = useStaking();
const { getTokenApprovalActionsForSpender } = useTokenApprovalActions(
  [props.pool.address],
  ref([balanceFor(props.pool.address).toString()])
);

const stakeAction = {
  label: t('stake'),
  loadingLabel: t('staking.staking'),
  confirmingLabel: t('confirming'),
  action: stakeBPT,
  stepTooltip: t('staking.stakeTooltip')
};

const unstakeAction = {
  label: t('unstake'),
  loadingLabel: t('staking.unstaking'),
  confirmingLabel: t('confirming'),
  action: unstakeBPT,
  stepTooltip: t('staking.unstakeTooltip')
};

/**
 * STATE
 */
const isLoadingApprovalsForGauge = ref(false);
const isActionConfirmed = ref(false);
const confirmationReceipt = ref<TransactionReceipt>();
const stakeActions = ref<TransactionActionInfo[]>([]);
const shareBalanceToDisplay = ref(
  props.action === 'unstake'
    ? stakedSharesForProvidedPool.value
    : balanceFor(props.pool.address)
);

/**
 * WATCHERS
 */
watch(
  () => props.action,
  () => {
    stakeActions.value =
      props.action === 'stake' ? [stakeAction] : [unstakeAction];
  },
  { immediate: true }
);

/* COMPUTED */
const assetRowWidth = computed(() => (props.pool.tokensList.length * 32) / 1.5);

const numSharesToModify = ref(
  props.action === 'stake'
    ? balanceFor(getAddress(props.pool.address))
    : stakedSharesForProvidedPool.value
);

const fiatValueOfModifiedShares = ref(
  bnum(props.pool.totalLiquidity)
    .div(props.pool.totalShares)
    .times(numSharesToModify.value)
    .toString()
);

const totalUserPoolSharePct = ref(
  bnum(
    bnum(stakedSharesForProvidedPool.value).plus(
      balanceFor(getAddress(props.pool.address))
    )
  )
    .div(props.pool.totalShares)
    .toString()
);

/**
 * LIFECYCLE
 */
onBeforeMount(async () => {
  await loadApprovalsForGauge();
});

/** METHODS */
async function handleSuccess({ receipt }) {
  isActionConfirmed.value = true;
  confirmationReceipt.value = receipt;
  await refetchStakedShares.value();
  await refetchUserStakingData.value();
  await queryClient.refetchQueries(['staking']);
  emit('success');
}

async function loadApprovalsForGauge() {
  isLoadingApprovalsForGauge.value = true;
  const gaugeAddress = await getGaugeAddress(props.pool.address, getProvider());
  const approvalActions = await getTokenApprovalActionsForSpender(gaugeAddress);
  stakeActions.value.unshift(...approvalActions);
  isLoadingApprovalsForGauge.value = false;
}

function handleClose() {
  isActionConfirmed.value = false;
  confirmationReceipt.value = undefined;
  emit('close');
}

return (_ctx: any,_cache: any) => {
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalCircle = _resolveComponent("BalCircle")!
  const _component_BalStack = _resolveComponent("BalStack")!
  const _component_BalAssetSet = _resolveComponent("BalAssetSet")!
  const _component_BalCard = _resolveComponent("BalCard")!
  const _component_BalTooltip = _resolveComponent("BalTooltip")!
  const _component_BalActionSteps = _resolveComponent("BalActionSteps")!
  const _component_BalBtn = _resolveComponent("BalBtn")!

  return (_openBlock(), _createBlock(_component_BalStack, { vertical: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_BalStack, {
        horizontal: "",
        spacing: "sm",
        align: "center"
      }, {
        default: _withCtx(() => [
          (isActionConfirmed.value)
            ? (_openBlock(), _createBlock(_component_BalCircle, {
                key: 0,
                size: "8",
                color: "green",
                class: "text-white"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BalIcon, { name: "check" })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("h4", null, _toDisplayString(_ctx.$t(`${__props.action}`)) + " " + _toDisplayString(_ctx.$t('lpTokens')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_BalCard, {
        shadow: "none",
        noPad: "",
        class: "px-4 py-2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BalStack, {
            horizontal: "",
            justify: "between",
            align: "center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BalStack, {
                vertical: "",
                spacing: "none"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h5", null, _toDisplayString(_unref(fNum2)(shareBalanceToDisplay.value)) + " " + _toDisplayString(_ctx.$t('lpTokens')), 1),
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(getToken)(__props.pool.address).symbol), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_BalAssetSet, {
                addresses: __props.pool.tokenAddresses,
                width: _unref(assetRowWidth),
                size: 32
              }, null, 8, ["addresses", "width"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_BalCard, {
        shadow: "none",
        noPad: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h6", _hoisted_3, _toDisplayString(_ctx.$t('summary')), 1)
          ]),
          _createVNode(_component_BalStack, {
            vertical: "",
            spacing: "xs",
            class: "p-3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BalStack, {
                horizontal: "",
                justify: "between"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_4, [
                    _createTextVNode(_toDisplayString(_ctx.$t('totalValueTo')) + " ", 1),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(__props.action === 'stake' ? _ctx.$t('stake') : _ctx.$t('unstake')) + ": ", 1)
                  ]),
                  _createVNode(_component_BalStack, {
                    horizontal: "",
                    spacing: "base"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_6, " ~" + _toDisplayString(_unref(fNum2)(fiatValueOfModifiedShares.value, _unref(FNumFormats).fiat)), 1),
                      _createVNode(_component_BalTooltip, {
                        text: 
                __props.action === 'stake'
                  ? _ctx.$t('staking.stakeValueTooltip')
                  : _ctx.$t('staking.unstakeValueTooltip')
              ,
                        width: "40",
                        textAlign: "center"
                      }, null, 8, ["text"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_BalStack, {
                horizontal: "",
                justify: "between"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('staking.newTotalShare')) + ":", 1),
                  _createVNode(_component_BalStack, {
                    horizontal: "",
                    spacing: "base"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_8, " ~" + _toDisplayString(_unref(fNum2)(totalUserPoolSharePct.value, _unref(FNumFormats).percent)), 1),
                      _createVNode(_component_BalTooltip, {
                        text: _ctx.$t('staking.totalShareTooltip'),
                        width: "40",
                        textAlign: "center"
                      }, null, 8, ["text"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (!isActionConfirmed.value)
        ? (_openBlock(), _createBlock(_component_BalActionSteps, {
            key: 0,
            actions: stakeActions.value,
            isLoading: isLoadingApprovalsForGauge.value,
            onSuccess: handleSuccess
          }, null, 8, ["actions", "isLoading"]))
        : _createCommentVNode("", true),
      (isActionConfirmed.value)
        ? (_openBlock(), _createBlock(_component_BalStack, {
            key: 1,
            vertical: ""
          }, {
            default: _withCtx(() => [
              _createVNode(ConfirmationIndicator, { txReceipt: confirmationReceipt.value }, null, 8, ["txReceipt"]),
              _createVNode(AnimatePresence, { isVisible: isActionConfirmed.value }, {
                default: _withCtx(() => [
                  _createVNode(_component_BalBtn, {
                    onClick: handleClose,
                    color: "gray",
                    outline: "",
                    block: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('close')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["isVisible"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})