import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex items-center justify-between text-gray-400 dark:text-gray-600 text-sm"
}
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "ml-2" }

import { TransactionReceipt } from '@ethersproject/abstract-provider';
import { computed, reactive } from 'vue';
import { useQuery } from 'vue-query';

import useConfig from '@/composables/useConfig';
import useEthers from '@/composables/useEthers';
import { dateTimeLabelFor } from '@/composables/useTime';
import QUERY_KEYS from '@/constants/queryKeys';
import useWeb3 from '@/services/web3/useWeb3';

type Props = {
  txReceipt: TransactionReceipt;
};

type ConfirmationData = {
  confirmedAt: string;
  explorerLink: string;
};


export default _defineComponent({
  props: {
    txReceipt: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  txReceipt: TransactionReceipt;
}) {

const props = __props


/**
 * COMPOSABLES
 */
const { getTxConfirmedAt } = useEthers();
const { explorerLinks } = useWeb3();
const { networkConfig } = useConfig();

/**
 * COMPUTED
 */
const isQueryEnabled = computed(() => !!receipt.value?.transactionHash);

const receipt = computed(() => props.txReceipt);

/**
 * QUERIES
 */
const {
  data: confirmationData,
  isLoading: isFetchingConfirmationDate,
  isIdle,
  error
} = useQuery<ConfirmationData>(
  QUERY_KEYS.Transaction.ConfirmationDate(receipt),
  async () => {
    const confirmedAt = await getTxConfirmedAt(receipt.value);
    const explorerLink = explorerLinks.txLink(receipt.value.transactionHash);
    return {
      confirmedAt: dateTimeLabelFor(confirmedAt),
      explorerLink
    };
  },
  reactive({ enabled: isQueryEnabled })
);

const isLoading = computed(
  (): boolean =>
    isFetchingConfirmationDate.value || isIdle.value || !!error.value
);

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!
  const _component_BalIcon = _resolveComponent("BalIcon")!
  const _component_BalLink = _resolveComponent("BalLink")!

  return (_unref(isLoading))
    ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
        key: 0,
        class: "h-6"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BalIcon, { name: "clock" }),
          _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(confirmationData)?.confirmedAt), 1)
        ]),
        _createVNode(_component_BalLink, {
          href: _unref(confirmationData)?.explorerLink,
          external: "",
          noStyle: "",
          class: "group flex items-center"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(networkConfig).explorerName) + " ", 1),
            _createVNode(_component_BalIcon, {
              name: "arrow-up-right",
              size: "sm",
              class: "ml-px group-hover:text-pink-500 transition-colors"
            })
          ]),
          _: 1
        }, 8, ["href"])
      ]))
}
}

})